import React from 'react';
import styled from '@emotion/styled';
import { ServicePanel } from '../components/layout/Service-Panel';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';

const StyledServicePanels = styled.div`
    margin-top: 36px;
    > div {
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        margin-top: 72px;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
`;

export const ServicePanels: React.FC = () => {
    return (
        <StyledServicePanels className="container">
            {/* <ServicePanel url="/our-services/addiction/" service="Addiction">
                <StaticImage
                    src="../images/our-services/our-services-2.jpg"
                    alt="man struggling"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '} */}
            <ServicePanel url="/antiaging-medicine/" service="Anti-Aging Medicine">
                <StaticImage
                    src="../images/our-services/our-services-3.jpg"
                    alt="girl smiling in the mirror"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/conditions/" service="Conditions Treated">
                <StaticImage
                    src="../images/our-services/our-services-4.jpg"
                    alt="upset man holding glasses"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/services/treatments/energy-get-more-of-it/" service="Energy">
                <StaticImage
                    src="../images/our-services/our-services-5.jpg"
                    alt="girl running on treadmill"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/services/treatments/female-hormones/" service="Female Hormones">
                <StaticImage
                    src="../images/our-services/our-services-6.jpg"
                    alt="girl in pain"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/pain/" service="Pain">
                <StaticImage
                    src="../images/our-services/our-services-7.jpg"
                    alt="doctor examining elbow"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/services/treatments/sleep/" service="Sleep">
                <StaticImage
                    src="../images/our-services/our-services-8.jpg"
                    alt="restless woman unable to sleep"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/slow-metabolism/" service="Slow Metabolism">
                <StaticImage
                    src="../images/our-services/our-services-9.jpg"
                    alt="gloomy man starting at window"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/supplements/" service="Supplements">
                <StaticImage
                    src="../images/our-services/our-services-10.jpg"
                    alt="man taking medication"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/services/treatments/testosterone/" service="Testosterone">
                <StaticImage
                    src="../images/our-services/our-services-11.jpg"
                    alt="upset man sitting on bed"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            {/* <ServicePanel url="/our-services/therapies/" service="Therapies">
                <StaticImage
                    src="../images/our-services/our-services-12.jpg"
                    alt="upset man having a medical consultation"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '} */}
            <ServicePanel url="/services/hyperthyroidism/" service="Thyroid">
                <StaticImage
                    src="../images/our-services/our-services-13.jpg"
                    alt="woman holding her throat"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/services/treatments/weight-loss/" service="Weight Loss">
                <StaticImage
                    src="../images/our-services/our-services-14.jpg"
                    alt="weight loss"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
        </StyledServicePanels>
    );
};
