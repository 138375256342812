import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { ServicePanels } from '../components/Service-Panels';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/seo';

const breadCrumbNames = ['Services'];
const breadCrumbUrls = ['/our-services/'];

const OurServices: Page = () => {
    return (
        <>
            <Seo
                title="Our Services in Lafayette, IN - Innovative Medicine"
                description="Innovative Medicine medical team an array of different kinds of services to fit your needs."
            />
            <Hero
                title="Our Services"
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            >
                {' '}
                <StaticImage
                    className=""
                    src="../images/leg-stretching.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="man stretching woman's leg"
                />
            </Hero>
            <ServicePanels />
        </>
    );
};
OurServices.Layout = DefaultLayout;

export default OurServices;
